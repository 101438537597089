.main-container {
  //max-width: 1440px;
  margin: auto;
}

*{
  box-sizing: content-box !important;
}

h1, h2, h3 {
  margin: 0;
  text-align: -webkit-auto;
}

.erp {
  &-header {
    //width: 80.469vw;
    display: flex;
    align-items: center;
    padding: 1.111vw 4.931vw 1.111vw 4.931vw;
    //box-shadow: 0px 4px 10px rgb(0 0 0 / 6%);
    //padding: 32px 135px 0 135px;
    &__logo {
      //height: 67px;
      //width: 136px;
      height: 4.653vw;
      width: 9.444vw;
      //margin-right: 27.778vw;
    }
    &__group {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-end;
      a {
        text-decoration: none;
        color: #FFFFFF;
      }
    }
    &__tour {
      border: none;
      background: #3776CF;
      box-shadow: 0vw 0.347vw 3.472vw rgba(55, 118, 207, 0.25);
      border-radius: 0.556vw;
      padding: 0.833vw 1.875vw;
      font-style: normal;
      font-weight: bold;
      font-size: 1.111vw;
      line-height: 1.389vw;
      color: #FFFFFF;
      cursor: pointer;
    }
    &__login {
      border: 1px solid #3776cf4f;
      box-sizing: border-box;
      border-radius: 0.556vw;
      //padding: 0.833vw 1.875vw;
      padding: 0.833vw 1.875vw 0.903vw 1.875vw;
      font-weight: 600;
      font-size: 1.111vw;
      line-height: 1.389vw;
      align-items: center;
      text-align: center;
      color: #3776CF;
      margin-left: 1.736vw;
      background: none;
      cursor: pointer;
      height: 3.264vw;
    }
    &__burger {
      display: none;
      background: none;
      border: none;
    }
  }
  &-nav {
    //margin-right: 1.5vw;
    //margin-right: 3.889vw;
    &--mobile {
      display: none;
      border-radius: 0vw 0vw 5.333vw 5.333vw;
      transition: 1s;
    }
    &__list {
      width: 61.74vw;
      display: flex;
      align-items: center;
    }
    &__item {
      list-style-type: none;
      font-style: normal;
      font-weight: 500;
      font-size: 1.111vw;
      line-height: 1.389vw;
      margin-right: 1.182vw;
      a {
        color: rgba(48, 59, 74, 0.85);
        text-decoration: none;
        &:hover {
          color: #3776CF;
        }
      }
    }
    &__dropdown {
      position: relative;
      display: inline-block;
      cursor: pointer;
      span {
        color: rgba(48, 59, 74, 0.85);
        &:hover {
          color: #3776CF;
        }
      }
    }
    &__user {
      font-style: normal;
      font-weight: 500;
      font-size: 1.111vw;
      line-height: 1.389vw;
      color: #303b4ad9;
      border-bottom: 1px solid #d0d0d759;
      padding: 0 2.083vw 0.938vw 2.083vw;
      display: flex;
      align-items: center;
    }
    &__avatar {
      margin-right: 0.938vw;
    }
    &__username {
      cursor: default;
      color: #303B4A;
      font-size: 1.111vw;
      line-height: 1.389vw;
      font-weight: 600;
    }

    &__logout {
      font-style: normal;
      font-weight: 500;
      font-size: 1.111vw;
      line-height: 1.389vw;
      color: #303b4ad9;
      border-top: 1px solid #d0d0d759;
      padding-top: 0.876vw;
      margin-top: 5px;
      padding-left: 2.083vw;
      display: flex;
      align-items: center;
    }
    &__dropdown-content {
      display: none;
      position: absolute;
      left: -7.6039vw;
      background-color: #ffffff;
      min-width: 16.304vw;
      box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 6%);
      padding: 1.042vw 0;
      z-index: 1;
    }
    &__dropdown-item {
      padding: 0.521vw 0 0.521vw 2.083vw;
      list-style-type: none;
      font-style: normal;
      font-weight: 500;
      font-size: 1.111vw;
      line-height: 1.389vw;
      a {
        color: rgba(48, 59, 74, 0.85);
        text-decoration: none;
        &:hover {
          color: #3776CF;
        }
      }
    }

    &__dropdown:hover .erp-nav__dropdown-content {
      display: block;
    }
    &__active {
      a {
        color: #3776CF;
      }
    }
  }
  &-content {
    //width: 100%;
    //max-width: 1300px;
    //margin: auto;
    padding-left: 4.931vw;
    padding-right: 4.931vw;
    margin-top: 2.222vw;
    margin-bottom: 2.222vw;
  }
  &-help {
    margin-top: 6.944vw;
    margin-bottom: -2.083vw;
    height: 24.444vw;
    background: #F8F8FA;
    &__title {
      padding-top: 5vw;
      font-style: normal;
      font-weight: bold;
      font-size: 2.639vw;
      line-height: 3.194vw;
      text-align: center;
      color: #303B4A;
      margin-bottom: 1.667vw;
    }
    &__text {
      font-style: normal;
      font-weight: 500;
      font-size: 1.25vw;
      line-height: 1.528vw;
      color: #727A84;
      text-align: center;
    }
    &__btn {
      margin: 2.292vw 0;
      text-align: center;
    }
    &__tour {
      &-btn {
        border: none;
        background: #3776CF;
        box-shadow: 0vw 0.347vw 3.472vw rgba(55, 118, 207, 0.25);
        border-radius: 0.556vw;
        padding: 0.833vw 1.875vw;
        font-style: normal;
        font-weight: bold;
        font-size: 1.111vw;
        line-height: 1.389vw;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
  }
  &-footer {
    width: 100%;
    background: #303B4A;
    //border-radius: 35px 35px 0px 0px;
    border-radius: 2.431vw 2.431vw 0vw 0vw;
    //height: 364px;
    height: 25.278vw;
    display: flex;
    &__asa-pc {
      display: block
    }
    &__asa-mobile {
      display: none;
    }
    &__col {
      width: 12vw;
      padding-top: 5vw;
      &:first-child {
        width: 25.972vw;
      }
      &:nth-child(2) {
        width: 17.569vw;
        padding-top: 5vw;
      }
      &:nth-child(3) {
        width: 17.569vw;
        padding-top: 5vw;
      }
      &:nth-child(4) {
        width: 17.569vw;
        padding-top: 5vw;
      }
      &:last-child {
        width: 21vw;
      }
      li {
        //margin-bottom: 12px;
        margin-bottom: 0.833vw;
      }
      &-title {
        font-style: normal;
        font-weight: 600;
        //font-size: 20px;
        //line-height: 24px;
        //color: #FFFFFF;
        //margin-bottom: 32px;
        font-size: 1.389vw;
        line-height: 1.667vw;
        color: #FFFFFF;
        margin-bottom: 2.222vw;
      }
    }
    &__logo {
      text-align: center;
      &-btn {
        height: 4.653vw;
        width: 9.444vw;
      }
    }
    &__navigation {
      list-style-type: none;
      font-style: normal;
      font-weight: 500;
      //font-size: 16px;
      //line-height: 20px;
      font-size: 1.111vw;
      line-height: 1.389vw;
      color: rgba(255, 255, 255, 0.85);
      padding-left: 0;
      &--link {
        a {
          text-decoration: none;
          color: rgba(255, 255, 255, 0.85);
        }
      }
    }
    &__resources {
      list-style-type: none;
      font-style: normal;
      font-weight: 500;
      //font-size: 16px;
      //line-height: 20px;
      font-size: 1.111vw;
      line-height: 1.389vw;
      color: rgba(255, 255, 255, 0.85);
      padding-left: 0;
    }
    &__company {
      list-style-type: none;
      font-style: normal;
      font-weight: 500;
      //font-size: 16px;
      //line-height: 20px;
      font-size: 1.111vw;
      line-height: 1.389vw;
      color: rgba(255, 255, 255, 0.85);
      padding-left: 0;
    }
    &__icon {
      width: 2.222vw;
      height: 2.222vw;
      margin-right: 1.111vw;
    }
  }
  &-global-search {
    $parent-class: &;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 78.125vw;
    &__select {
      width: 100%;
      height: 100%;
      margin-left: 0.521vw;

      .el-input {
        height: 100%;
      }
      .el-input__inner {
        width: 100%;
        height: 100%;
        padding-left: 38px !important;
        font-size: 13px;
        background: url('/images/icon-search.svg') left 10px center no-repeat;
        -svg-fill: #8B90A3;
        overflow: hidden;
        text-overflow: ellipsis;
        border: none;
      }
      .el-input__suffix {
        .el-select__caret {
          background: none;
        }
      }
    }
    .el-select__tags {
      padding-left: 38px;
    }
    &__link {
      $link-class: &;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 280px;
      text-decoration: none !important;
      &,
      &:hover,
      &:visited {
        color: #101415;
      }
      &:hover {
        #{$link-class}-label,
        #{$link-class}-time {
          color: #FFFFFF;
        }
      }
      &-label {
        font-weight: 500;
        width: 175px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      &-time {
        width: 85px;
        color: #898fa1;
        //opacity: 0.5;
        font-size: 0.8em;
      }
    }

    &__dropdown {
      left: 16.302vw !important;
      width: 69.94vw !important;
      box-shadow: none !important;
      &-header {
        font-size: 13px;
        border-bottom: 1px solid #D6DEE1;
        padding-left: 20px;
        padding-top: 10px;
        padding-bottom: 13px;
        font-weight: 600;
      }

      .el-select-dropdown__item {
        height: 70px;
        &:hover {
          background: #FFFFFF;
          .erp-global-search__dropdown-options__info--pn {
            color: #3776CF;
          }
        }
      }
      .el-select-dropdown__item.hover {
        background: #FFFFFF;
        .erp-global-search__dropdown-options__info--pn {
          color: #3776CF;
        }
      }
      &-options {
        display: flex;
        height: 70px;
        color: rgba(48, 59, 74, 0.85);
        &__image {
          display: flex;
          align-items: center;
          margin-right: 10px;
          img {
            width: 55px;
            height: 55px;
          }
        }
        &__info {
          margin-right: 10px;
          font-size: 12px;
          display: flex;
          flex: 1;
          padding-top: 15px;
          font-weight: 600;
          &--pn {
            margin-right: 10px;
          }
          &--desc {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &__btns {

        }
      }
      .popper__arrow {
        display: none !important;
      }
    }
    &--popover {
      margin-right: 0.521vw;
      width: 3.021vw;
    }
    &--popover-input {
      //width: 1500px;
      width: 68.802vw;
      position: absolute !important;
      left: 16.302vw !important;
      .popper__arrow {
        display: none !important;
      }
    }
    &--btn {
      border: none !important;
    }
  }
  &-shopping-cart {
    display: flex;
    &--count {
      width: 0.677vw;
      height: 0.677vw;
      background: #35C835;
      position: relative;
      border-radius: 50%;
      top: -0.417vw;
      right: 1.042vw;
      font-size: 0.469vw;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
    }
  }
}

.block-video {
  display: flex;
  background: #F8F8FA;
  //border-radius: 30px;
  border-radius: 2.083vw;
  //padding: 64px;
  padding: 4.444vw;
  &__text {
    //flex: 1.3;
    //margin-right: 5%;
    width: 29.931vw;
    height: 21.944vw;
    margin-right: 2.778vw;
    &-title {
      font-style: normal;
      font-weight: bold;
      //font-size: 48px;
      //line-height: 59px;
      font-size: 3.333vw;
      line-height: 4.097vw;
      display: flex;
      align-items: center;
      color: #303B4A;
    }
    &-info {
      font-style: normal;
      font-weight: normal;
      //font-size: 18px;
      //line-height: 31px;
      font-size: 1.25vw;
      line-height: 2.153vw;
      color: rgba(48, 59, 74, 0.85);
      //margin-top: 24px;
      margin-top: 1.667vw;
      //margin-right: 85px;
      //margin-right: 5.903vw;
      margin-right: 2.9vw;
    }
    &-btn {
      //margin-top: 33px;
      margin-top: 2.292vw;
    }
    &-request {
      background: #3776CF;
      //box-shadow: 0px 5px 50px rgb(55 118 207 / 25%);
      //border-radius: 8px;
      box-shadow: 0vw 0.347vw 3.472vw rgb(55 118 207 / 25%);
      border-radius: 0.556vw;
      border: none;
      //padding: 12px 27px;
      padding: 0.833vw 1.875vw;
      font-weight: bold;
      //font-size: 16px;
      //line-height: 20px;
      font-size: 1.111vw;
      line-height: 1.389vw;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
      cursor: pointer;
    }
    &-watch {
      border: 1px solid #3776cf4f;
      box-sizing: border-box;
      //border-radius: 8px;
      border-radius: 0.556vw;
      //padding: 12px 27px;
      padding: 0.833vw 1.875vw;
      font-weight: 600;
      //font-size: 16px;
      //line-height: 20px;
      font-size: 1.111vw;
      line-height: 1.389vw;
      align-items: center;
      text-align: center;
      color: #3776CF;
      //margin-left: 25px;
      margin-left: 1.736vw;
      background: none;
      cursor: pointer;
    }
  }
  &__video {
    //flex: 3;
    width: 48.611vw;
    height: 25.833vw;
    &--img {
      width: 48.611vw;
      display: block;
    }
    &--video {
      width: 48.611vw;
      height: 100%;
      display: none;
    }
  }
  &--mobile {
    display: none;
  }
}

.info-block {
  //padding-right: 141px;
  //padding-right: 5.208vw;
  &__pre-title {
    font-style: normal;
    font-weight: 500;
    font-size: 1.25vw;
    line-height: 1.528vw;
    margin-bottom: 0.556vw
  }
  &__title{
    font-style: normal;
    font-weight: bold;
    font-size: 2.639vw;
    line-height: 3.194vw;
    display: flex;
    align-items: center;
    color: #303B4A;
    margin-bottom: 1.667vw;
  }
  &__text {
    white-space: pre-wrap;
    font-style: normal;
    font-weight: normal;
    font-size: 1.111vw;
    line-height: 1.944vw;
    color: rgba(48, 59, 74, 0.85);
    margin-bottom: 1.667vw;
  }
  &__btns {
    a {
      text-decoration: none;
      //color: #3776CF;
    }
    &-more {
      //border: 1px solid #3776CF;
      box-sizing: border-box;
      //border-radius: 8px;
      border-radius: 0.556vw;
      font-weight: 600;
      //font-size: 16px;
      //line-height: 20px;
      font-size: 1.111vw;
      line-height: 1.389vw;
      display: flex;
      align-items: center;
      text-align: center;
      //color: #3776CF;
      //padding: 12px 27px;
      //padding: 0.833vw 1.875vw;
      background: none;
      cursor: pointer;
      min-width: 10.347vw;
      max-width: 12vw;
      width: auto;
      height: 3.125vw;
      justify-content: center;
      &--white {
        color: #3776CF;
        border: 1px solid #3776CF;
        &:visited {
          color: #3776CF;
        }
      }
      &--blue {
        color: #FFFFFF;
        background: #3776CF;
        &:visited {
          color: #FFFFFF;
        }
      }
      &--green {
        color: #FFFFFF;
        background: #20AC47;
        &:visited {
          color: #FFFFFF;
        }
      }
    }
  }
}

.two-column {
  //display: flex;
  //flex-flow: row wrap;
  //padding: 120px 71px 0 71px;
  padding: 0 4.931vw 0 4.931vw;
  &--top-padding-default {
    padding-top: 8.333vw;
  }
  &--top-padding-medium {
    padding-top: 3.889vw;
  }
  &--top-padding-none {
    padding-top: 0;
  }
  &--mobile {
    display: none;
  }
  &__columns {
    display: flex;
    flex-flow: row wrap;
  }
  &__col {
    display: flex;
    flex-direction: column;
    flex: 1;
    color: #4F5865;
    margin-right: 7.917vw;
    &:last-child {
      margin-right: 0;
    }
    .bp-rich-text {
      font-size: 1.111vw;
      line-height: 1.944vw;
    }
  }
  &__title {
    font-weight: bold;
    font-size: 3.333vw;
    line-height: 4.097vw;
    color: #303B4A;
    display: flex;
    &--margin-default {
      margin-bottom: 3.333vw;
    }
    &--margin-none {
      margin-bottom: 0;
    }
  }
}

.block-showcase {
  //padding: 88px 64px 74px 64px;
  //background: #F6F6F6;
  //border-radius: 30px;
  //margin-top: 120px;
  padding: 6.111vw 4.444vw 5.139vw 4.444vw;
  background: #F8F8FA;
  border-radius: 2.083vw;
  //margin-top: 6.667vw;
  &__title {
    font-weight: bold;
    //font-size: 38px;
    //line-height: 46px;
    //padding: 0 370px;
    font-size: 2.639vw;
    line-height: 3.194vw;
    padding: 0 18.75vw;
    color: #303B4A;
    text-align: center;
    //margin-bottom: 50px;
    margin-bottom: 3.472vw;
  }
  &__content {
    width: 89.583vw;
    .apos-areas-widgets-list {
      display: flex;
      flex-wrap: wrap;
      .apos-area-widget-wrapper:nth-child(3n+3) {
        .showcase-block {
          margin-right: 0;
        }
      }
    }
    .apos-area {
      display: flex;
      flex-wrap: wrap;
      section:nth-child(3n+3) {
        .showcase-block {
          margin-right: 0;
        }
      }
    }
  }
  &__row {
    display: flex;
    //margin-bottom: 20px;
    margin-bottom: 1.389vw
    //padding: 0 50px;
  }
  &__item {
    //padding: 40px 140px;
    //background: #FFFFFF;
    //border-radius: 15px;
    //width: 39.931vw;
    //width: 39.931vw;
    //height: 17.847vw;
    padding: 1.563vw 0;
    //background: #FFFFFF;
    border-radius: 1.042vw;
    margin-bottom: 1.389vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    &-icon {
      //width: 55px;
      //padding-left: 135px;
      //margin-bottom: 20px;
      //padding-left: 18.403vw;
      display: flex;
      justify-content: center;
      img {
        height: 4.861vw;
        margin-bottom: 1.389vw;
      }
    }
    &-title {
      font-weight: 600;
      //font-size: 24px;
      //line-height: 29px;
      //text-align: center;
      //color: #303B4A;
      //margin-bottom: 15px;
      font-size: 1.667vw;
      line-height: 2.014vw;
      //text-align: center;
      color: #303B4A;
      margin-bottom: 1.042vw;
      padding: 0 4.653vw 0 4.583vw;
    }
    &-text {
      font-style: normal;
      font-weight: normal;
      //font-size: 16px;
      //line-height: 28px;
      font-size: 1.111vw;
      line-height: 1.944vw;
      //display: flex;
      //align-items: center;
      //text-align: center;
      color: rgba(48, 59, 74, 0.85);
      padding: 0 4.653vw 0 4.583vw;
    }
    &:first-child {
      //margin-right: 20px;
      margin-right: 1.389vw;
    }
  }
}

.block-carousel {
  //margin-top: 60px;
  //margin-bottom: 120px;
  //margin-top: 6.111vw;
  //margin-bottom: 4.583vw;
  background: #F8F8FA;
  padding-bottom: 4.583vw;
  padding-top: 7.361vw;
  margin-top: 7.778vw;
  border-radius: 2.083vw;
  &__title {
    font-weight: bold;
    //font-size: 38px;
    //line-height: 46px;
    font-size: 2.639vw;
    line-height: 3.194vw;
    text-align: center;
    color: #303B4A;
    //padding-bottom: 50px;
    padding-bottom: 3.472vw;
  }
  &__slider {
    display: flex;
    &-item {
      //flex: 1;
      background: #FFFFFF;
      border: 1.5px solid #EFEFEF;
      box-sizing: border-box;
      //border-radius: 20px;
      //height: 351px;
      border-radius: 1.389vw;
      height: 24.375vw;
      display: flex;
    }
    &-content {
      display: flex;
      &--mobile {
        display: none;
      }
    }
    &-col1 {
      border-right: 1px solid #EFEFEF;
      //padding: 70px 60px 0 60px;
      padding: 4.861vw 4.167vw 0 4.167vw;
      width: 13.194vw;
      &--logo {
        width: 13.194vw;
      }
      &--person {
        font-weight: 600;
        //font-size: 20px;
        //line-height: 175%;
        font-size: 1.389vw;
        line-height: 2.431vw;
        color: #3776CF;
        text-align: center;
        //margin-top: 15px;
        margin-top: 1.042vw;
      }
      &--position {
        //font-size: 16px;
        //line-height: 145%;
        font-size: 1.111vw;
        line-height: 1.597vw;
        text-align: center;
        color: #9A9FA6;
      }
    }
    &-col2 {
      //flex: 1;
      //padding: 50px 108px 50px 50px;
      //font-style: normal;
      //font-weight: normal;
      //font-size: 16px;
      //line-height: 175%;
      padding: 3.333vw 7.5vw 3.472vw 3.333vw;
      width: 31.875vw;
      font-style: normal;
      font-weight: normal;
      font-size: 1.111vw;
      line-height: 1.944vw;
      color: rgba(48, 59, 74, 0.85);
    }
    &-arrow {
      //flex: 0.2;
      //padding-top: 140px;
      width: 12.986vw;
      padding-top: 9.722vw;
      text-align: center;
      &--icon {
        width: 3.889vw;
        height: 3.889vw;
      }
    }
  }
}

.block-diptych {
  //margin-top: 4.444vw;
  //margin-bottom: 3.472vw;
  padding-top: 4.444vw;
  padding-bottom: 3.472vw;
  border-radius: 2.083vw;
  &__title {
    padding: 3.333vw 0;
    font-style: normal;
    font-weight: bold;
    font-size: 2.639vw;
    line-height: 3.194vw;
    color: #3776CF;
    max-width: 36.111vw;
    margin: auto;
    text-align: center;
  }
  &__content {
    display: flex;
    padding: 0 4.514vw;
    &--mobile {
      display: none;
    }
  }
  &__item {
    width: 39.931vw;
    &:first-child {
      margin-right: 1.389vw
    }
  }
}

.block-list {
  //margin-top: 8.333vw;
  border-radius: 2.083vw;
  padding: 3.056vw 0;
  &__title {
    padding: 3.333vw 0;
    font-style: normal;
    font-weight: bold;
    font-size: 2.639vw;
    line-height: 3.194vw;
    color: #303B4A;
    text-align: center;
  }
  &__list {
    padding: 0 4.514vw;
  }
  &__item {
    display: flex;
    align-items: center;
    padding: 2.153vw 0;
    border-bottom: 1px solid #E4E4E4;
    &--mobile {
      display: none;
    }
    &-number {
      font-style: normal;
      font-weight: 200;
      font-size: 5vw;
      line-height: 6.111vw;
      width: 12.5vw;
      color: #3776CF;
    }
    &-title {
      font-style: normal;
      font-weight: 600;
      font-size: 1.667vw;
      line-height: 2.014vw;
      color: #303B4A;
      width: 41.319vw;
      p {
        line-height: 2.014vw;
        margin-top: 0;
        margin-bottom: 3px;
      }
    }
    &-text {
      font-style: normal;
      font-weight: 500;
      font-size: 1.111vw;
      line-height: 1.944vw;
      color: rgba(48, 59, 74, 0.85);
      width: 26.181vw
    }
  }
  &__list {
    .apos-areas-widgets-list {
      .apos-area-widget-wrapper:last-child {
        .block-list__item {
          border-bottom: none;
        }
      }
    }
    .apos-area {
      section:last-child {
        .block-list__item {
          border-bottom: none;
        }
      }
    }
  }
}

.calendly {
  text-align: center;
  &-block {
    width: 100%;
    height: 700px;
  }
}

.timeline {
  &-item {
    display: flex;
    height: auto;
    min-height: 6.944vw;
    &--mobile {
      display: none;
    }
    &__block {
      width: 74.375vw;
      border-top: 1px solid #E4E6EA;
      display: flex;
      align-items: center;
      padding: 40px 0;
    }
    &__line {
      width: 10.417vw;
      display: flex;
      align-items: center;
      border-left: 1px dashed #D2D3D4;
      position: relative;
      left: 2.778vw;
      &:before {
        content: "";
        position: absolute;
        margin-left: -1.389vw;
        background: #979EA6;
        border-radius: 50%;
        border: 1.042vw solid #FFFFFF;
        padding: 0.347vw;
      }
    }
    &__date {
      width: 15.833vw;
      font-style: normal;
      font-weight: normal;
      font-size: 2.083vw;
      line-height: 2.569vw;
      color: #3776CF;
    }
    &__title {
      width: 35.278vw;
      font-style: normal;
      font-weight: 600;
      font-size: 1.528vw;
      line-height: 1.875vw;
      color: #303B4A;
      .bp-rich-text {
        font-weight: 600;
        font-size: 1.528vw;
        line-height: 2.3vw;
      }
    }
    &__text {
      width: 23.264vw;
      font-weight: normal;
      font-size: 1.111vw;
      line-height: 1.944vw;
      color: #58667A;
      .bp-rich-text {
        font-weight: normal;
        font-size: 1.111vw;
        line-height: 1.944vw;
      }
    }
  }
  .apos-area-widget-wrapper:first-child {
    .timeline-item__block {
      border-top: none;
    }
  }
  .apos-area {
    section:first-child {
      .timeline-item__block {
        border-top: none;
      }
    }
  }
}

.contact-us {
  display: flex;
  flex-direction: column;
  padding: 0 3.889vw;
  &__title {
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 3.333vw;
    line-height: 4.097vw;
    color: #303B4A;
    margin-bottom: 3.333vw;
  }
  &__content {
    display: flex;
    border: 1.5px solid #EFEFEF;
    border-radius: 1.042vw;
    //padding: 3.889vw;
  }
  &__info {
    width: 30.556vw;
    background: #303B4A;
    padding: 3.889vw;
    color: #FFFFFF;
    border-radius: 0vw 1.042vw 1.042vw 0vw;
    span {
      opacity: 75%;
    }
    &--title {
      font-style: normal;
      font-weight: 600;
      font-size: 1.667vw;
      line-height: 2.014vw;
      color: #FFFFFF;
      margin-bottom: 3.889vw;
    }
    &--phone {
      font-style: normal;
      font-weight: 600;
      font-size: 1.111vw;
      line-height: 1.389vw;
      color: #FFFFFF;
      margin-bottom: 0.972vw;
    }
    &--email {
      font-style: normal;
      font-weight: 600;
      font-size: 1.111vw;
      line-height: 1.389vw;
      color: #FFFFFF;
      margin-bottom: 0.972vw;
    }
    &--address {
      font-style: normal;
      font-weight: 600;
      font-size: 1.111vw;
      line-height: 1.806vw;
      color: #FFFFFF;
      margin-bottom: 2.917vw;
    }
    &--social {
      display: flex;
      margin-bottom: 2.917vw;
      &-icon {
        width: 2.222vw;
        height: 2.222vw;
        margin-right: 1.111vw
      }
    }
    &--map {
      margin-bottom: 0.972vw;
      img {
        border-radius: 0.417vw;
        width: 100%;
      }
    }
    &--link-map {
      font-style: normal;
      font-weight: 600;
      font-size: 1.111vw;
      line-height: 1.944vw;
      text-decoration-line: underline;
      a {
        color: #FFFFFF;
        cursor: pointer;
      }
      &-icon {
        width: 1.111vw;
        height: 1.111vw;
        margin-left: 0.694vw;
        cursor: pointer;
      }
    }
  }
  &__form {
    width: 50.694vw;
    padding: 3.889vw;
    &--title {
      font-style: normal;
      font-weight: 600;
      font-size: 1.667vw;
      line-height: 2.014vw;
      color: #303B4A;
      margin-bottom: 3.889vw;
    }
    &--row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.222vw
    }
    &--input {
      input {
        width: 19.444vw;
        border: none;
        border-bottom: 1px solid rgba(144, 156, 178, 0.8);
        padding: 1.111vw 0;
        font-style: normal;
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.389vw;
        color: #303B4A;
        outline:none;
      }
    }
    &--textarea {
      width: 100%;
      border: none;
      border-bottom: 1px solid rgba(144, 156, 178, 0.8);
      padding: 1.111vw 0;
      font-style: normal;
      font-weight: 500;
      font-size: 1.111vw;
      line-height: 1.389vw;
      color: #929AA7;
      outline:none;
    }
    &--btn {
      display: flex;
      justify-content: center;
      margin-top: 3.889vw
    }
    &--submit {
      border: none;
      background: #3776CF;
      box-shadow: 0vw 0.347vw 3.472vw rgb(55 118 207 / 25%);
      border-radius: 0.556vw;
      padding: 0.833vw 1.875vw;
      font-style: normal;
      font-weight: bold;
      font-size: 1.111vw;
      line-height: 1.389vw;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
}

.title-widget {
  display: flex;
  justify-content: center;
  align-items: center;
  &--margin-default {
    margin-bottom: 2.778vw;
  }
  &--margin-small {
    margin-bottom: 1.778vw;
  }
  &--margin-none {
    margin-bottom:0;
  }
  //margin-bottom: 2.778vw;
  &__text {
    font-style: normal;
    font-weight: bold;
    font-size: 3.333vw;
    line-height: 4.097vw;
    color: #303B4A;
    //width: 45.764vw;
    text-align: center;
    &--48 {
      font-size: 3.333vw;
    }
    &--32 {
      font-size: 2.222vw;
    }
    &--28 {
      font-size: 1.944vw;
    }
    &--24 {
      font-size: 1.667vw;
    }
    &--18 {
      font-size: 1.250vw;
    }
    &--14 {
      font-size: 0.972vw;
    }
  }
}

.document {
  &-block {
    height: 4.167vw;
    display: flex;
    align-items: center;
    padding-left: 4.444vw;
  }
  &-file {
    padding-left: 0.694vw;
    &--icon {
      width: 1.141vw;
      height: 1.141vw;
    }
  }
  &-link, a:link, a:visited {
    font-size: 18px;
    text-decoration: none;
    color: #455A7E;
    font-weight: 600;
    display: flex;
    align-content: center;
  }
  &-title {
    display: flex;
    align-items: center;
  }
}

.iframe-widget {
  width: 100%;
  height: 115vh;
}

.catalog {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  &-filters {
    width: 17vw;
    margin-right: 2.604vw;
    padding-top: 1.25rem;
    &--title {
      font-style: normal;
      font-weight: 500;
      font-size: 1.111vw;
      line-height: 1.389vw;
      color: rgba(48, 59, 74, 0.85);
      text-transform: uppercase;
      margin-bottom: 2.083vw;
    }
    &--list {
      margin-bottom: 2.083vw;
    }
    &--btns {
      display: flex;
      justify-content: space-between;
    }
    &--btn {
      border: none;
      background: #3776CF;
      box-shadow: 0vw 0.347vw 3.472vw rgb(55 118 207 / 25%);
      border-radius: 0.556vw;
      font-style: normal;
      font-weight: bold;
      font-size: 0.951vw;
      line-height: 1.389vw;
      color: #FFFFFF;
      cursor: pointer;
      height: 2.078vw;
      padding: 0 1.382vw;
    }
  }
  &-multi-search {
    border: 1px solid #E9EAEF;
    border-radius: 0.525vw;
    &__header {
      height: 2.677vw;
      padding-left: 1.627vw;
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 0.735vw;
      line-height: 0.997vw;
      letter-spacing: 0.026vw;
      text-transform: uppercase;
      color: #4D5260;
      border-bottom: 1px solid #E9EAEF;
    }
    &__list {
      padding: 0 1.102vw 1.102vw 1.102vw;
    }
    &__row {
      display: flex;
      margin-top: 1.102vw;
    }
    &__search {
      cursor: pointer;
      height: 2.677vw;
      background: rgba(55, 118, 207, 0.08);
      border: 1px solid rgba(55, 118, 207, 0.04);
      border-radius: 0 0 0.525vw 0.525vw;
      font-weight: 700;
      font-size: 0.577vw;
      line-height: 0.787vw;
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: 0.063vw;
      text-transform: uppercase;
      color: #3776CF;
    }
    &__pn {
      width: 100%;
      // width: 37.5vw;
      margin-right: 2.604vw;
      input.multi-search-pn[type="search"]::-webkit-search-cancel-button {
        -webkit-appearance: none;
        height: 14px;
        width: 14px;
        display: block;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
        background-repeat: no-repeat;
        background-size: 14px;
      }
    }
    &__qty {
      display: none;
      width: 16.667vw;
      margin-right: 2.604vw;
    }
    &__add {
      &--btn {
        cursor: pointer;
        background: rgba(55, 118, 207, 0.08);
        border-radius: 6px;
        outline: none;
        border: none;
        height: 100%;
        width: 1.719vw;
        padding: 0;
      }
    }
    &__delete {
      &--btn {
        cursor: pointer;
        border-radius: 6px;
        outline: none;
        border: none;
        height: 100%;
        width: 1.719vw;
        margin-right: 0.694vw;
        padding: 0;
        background: rgba(235, 89, 69, 0.08);
      }
    }
    &__input {
      border-radius: 5px;
      border: 1px solid #b0b4bc;
      height: 1.563vw;
      line-height: 30px;
      outline: none;
      padding-left: 0.521vw;
      width: 100%;
      color: #8A91A5;
      &::-webkit-input-placeholder {
        opacity: 0.7;
      }
    }
  }
  &-block {
    width: 100%;
    &-pagination {
      display: flex;
      justify-content: space-between;
      margin-top: 1vw;
      margin-bottom: 0.321vw;
      &--btn {
        border-radius: 0.556vw;
        font-style: normal;
        font-weight: bold;
        font-size: 0.811vw;
        line-height: 1.189vw;
        color: #8A91A5;
        cursor: pointer;
        height: 2vw;
        padding: 0 0.8vw;
        border: 1px solid #E9EAEF;
        background: #fff;
      }
    }
  }
}

.catalog-filter {
  border: 1px solid #E9EAEF;
  border-radius: 0.842vw;
  margin-bottom: 1.042vw;
  &--title {
    height: 2.604vw;
    display: flex;
    align-items: center;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    padding-left: 1.042vw;
    font-size: 0.673vw;
    line-height: 0.781vw;
    font-weight: 700;
    color: #8A91A5;
    border-bottom: 1px solid #E9EAEF;
  }
  &-checkbox {
    padding: 0.833vw 1.042vw;
    color: #101415;
    font-style: normal;
    font-weight: 500;
    font-size: 0.729vw;
    line-height: 0.938vw;
    &--ratio {
      width: 0.781vw;
      height: 1.042vw;
    }
    &--label {
      margin-left: 0.417vw;
      font-size: 0.729vw;
      line-height: 0.938vw;
      font-weight: 500;
      font-style: normal;
    }
  }
  &-picklist {
    padding: 0.833vw 1.042vw;
    &--select {
      border-radius: 5px;
      border: 1px solid #b0b4bc;
      height: 30px;
      line-height: 30px;
      outline: none;
      width: 95%;
      padding-left: 0.521vw;
    }
  }
  &-input {
    padding: 0.833vw 1.042vw;
    input {
      border-radius: 5px;
      border: 1px solid #b0b4bc;
      height: 30px;
      line-height: 30px;
      outline: none;
      width: 95%;
      padding-left: 0.521vw;
      background: #ffffff;
    }
  }
}

.form_radio {
  margin-bottom: 10px;
}
.form_radio label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 25px;
  margin-right: 0;
  line-height: 18px;
  user-select: none;
}
.form_radio label:before {
  content: "";
  display: inline-block;
  width: 17px;
  height: 18px;
  position: absolute;
  left: 0;
  bottom: 1px;
}

/* Checked */
.form_radio input[type=radio]:checked + label:before {
}

/* Hover */
.form_radio label:hover:before {
  filter: brightness(120%);
}

/* Disabled */
.form_radio input[type=radio]:disabled + label:before {
  filter: grayscale(100%);
}

.bp-rich-text {
  p {
    margin: 0;
  }
  a {
    cursor: pointer;
  }
}

.table {
  border: 1px solid #9a9fa647;
  &-header {
    display: flex;
    height: 3.125vw;
    align-items: center;
    background: #F8F8FA;
    &__title {
      flex: 1;
      display: flex;
      justify-content: center;
      font-size: 18px;
      color: #9A9FA6;
      font-weight: 600;
      border-right: 1px solid #9a9fa647;
      &:last-child {
        border-right: none;
      }
    }
  }
  &-row {
    display: flex;
    border-bottom: 1px solid #9a9fa647;
    &__col {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #9a9fa647;
      &:last-child {
        border-right: none;
      }
      .apos-area-widget-inner {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.for-auth {
  display: none;
}

.contact-form {
  &-main {
    padding: 4.931vw;
  }
  &-title {
    font-style: normal;
    font-weight: bold;
    font-size: 2.139vw;
    line-height: 3.194vw;
    display: flex;
    align-items: center;
    color: #303B4A;
    margin-bottom: 1.667vw;
  }
  &-block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &-field {
    width: 50%;
    input {
      border: none;
      width: 90%;
      border-bottom: 1px solid rgba(144, 156, 178, 0.8);
      padding: 1.111vw 0;
      font-style: normal;
      font-weight: 500;
      font-size: 1.111vw;
      line-height: 1.389vw;
      color: #303B4A;
      outline: none;
    }
  }
  &-message {
    textarea {
      width: 45%;
      border: none;
      border-bottom: 1px solid rgba(144, 156, 178, 0.8);
      padding: 1.111vw 0;
      font-style: normal;
      font-weight: 500;
      font-size: 1.111vw;
      line-height: 1.389vw;
      color: #303B4A;
      outline: none;
    }
  }
  &-request {
    display: block;
  }
  &-success {
    display: none;
    &--message {
      font-style: normal;
      font-weight: bold;
      font-size: 2.139vw;
      line-height: 3.194vw;
      display: flex;
      align-items: center;
      color: #303B4A;
      margin-bottom: 1.667vw;
    }
  }
}

@media screen and (max-width: 767px) {
  .erp-content {
    padding: 0;
  }
  .block-video {
    display: none;
    &--mobile {
      display: block;
    }
    &__video {
      width: 100%;
      height: 100%;
      iframe {
        width: 100%;
        height: 100%;
      }
      &--img {
        width: 84vw;
        padding: 0 8vw;
      }
    }
    &__text {
      width: 84vw;
      height: 100%;
      margin-right: 0;
      padding: 0 8vw;
      margin-top: 5vw;
      &-btn {
        display: flex;
        justify-content: space-between;
      }
      &-title {
        font-size: 8.533vw;
        line-height: 10.4vw;
      }
      &-info {
        font-size: 4.267vw;
        line-height: 7.467vw;
        margin-right: 0;
      }
      &-request {
        font-size: 3.733vw;
        line-height: 4.533vw;
        padding: 3.733vw 6.133vw 4.267vw 6.133vw;
        height: 12.533vw;
        box-shadow: 0vw 1.333vw 13.333vw rgb(55 118 207 / 15%);
        border-radius: 2.133vw;
      }
      &-watch {
        font-size: 3.733vw;
        line-height: 4.533vw;
        height: 12.533vw;
        border-radius: 2.133vw;
        padding: 3.733vw 3.333vw;
        margin-left: 4.8vw;
      }
    }
  }
  .two-column {
    display: none;
    &--mobile {
      display: block;
      //padding: 8.333vw 0 0 0;
    }
    &--top-padding-default {
      padding-top: 8.333vw;
    }
    &--top-padding-none {
      padding-top: 0;
    }
    &__title {
      font-weight: bold;
      font-size: 8.533vw;
      line-height: 8vw;
      padding: 0 8vw;
      &--margin-default {
        margin-bottom: 3.333vw;
      }
      &--margin-none {
        margin-bottom: 0;
      }
    }
    &__col {
      display: block;
      .bp-rich-text {
        font-weight: normal;
        font-size: 4.267vw;
        line-height: 6.933vw;
        padding: 0 8vw;
        margin-bottom: 4.267vw;
      }
      &:last-child {
        margin-right: 0;
      }
      &:nth-child(2) {
        margin-top: 6.667vw;
      }
      &:nth-child(3) {
        margin-top: 6.667vw;
      }
      .bp-image-widget {
        padding: 0 8vw;
        width: 84vw;
        height: 47.2vw;
      }
    }
  }
  .info-block {
    padding: 0 8vw;
    &__pre-title {
      font-size: 4.8vw;
      line-height: 5.867vw;
      margin-bottom: 2.133vw;
    }
    &__title {
      font-size: 6.4vw;
      line-height: 8vw;
      margin-bottom: 3.467vw;
    }
    &__text {
      font-size: 4.267vw;
      line-height: 6.933vw;
    }
    &__btns {
      &-more {
        width: 40.733vw;
        height: 12.533vw;
        padding: 3.467vw 7.2vw 3.733vw 7.2vw;
        font-size: 4.267vw;
        line-height: 5.333vw;
        border-radius: 2.133vw;
      }
    }
  }
  .block-showcase {
    &__title {
      font-size: 6.4vw;
      line-height: 8vw;
      padding: 0;
    }
    &__content {
      .apos-area {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        section {
          .showcase-block {
            margin-right: 0;
          }
        }
      }
    }
    &__row {
      display: block;
    }
    &__item {
      width: 89.3vw !important;
      //min-height: 67.733vw !important;
      //height: 84.533vw !important;
      //width: 100%;
      margin-bottom: 4.267vw;
      border-radius: 4vw;
      padding: 8vw 0;
      height: auto !important;
      &-title {
        font-size: 5.333vw;
        line-height: 6.4vw;
      }
      &-text {
        font-size: 4.267vw;
        line-height: 6.933vw;
      }
      &:first-child {
        margin-right: 0;
      }
      &-icon {
        width: 10.819vw;
        padding-left: 40.067vw;
        margin-bottom: 4.267vw;
        img {
          height: 14.333vw;
          max-width: none;
        }
      }
    }
  }
  .block-carousel {
    padding: 0 8vw;
    margin-top: 16.111vw;
    &__title {
      font-size: 6.4vw;
      line-height: 8vw;
      text-align: inherit;
    }
    &__slider {
      display: block;
      &-item {
        border-radius: 4vw;
        height: auto;
        display: block;
      }
      &-content {
        display: none;
        &--mobile {
          display: block;
        }
      }
      &-btns {
        &--mobile {
          display: flex;
          padding-left: 27.733vw;
        }
      }
      &-col1 {
        border-bottom: 1px solid #EFEFEF;
        padding: 6.4vw 0 6.4vw 0;
        width: 100%;
        &--logo {
          margin-left: 21.933vw;
          width: 38.4vw;
        }
        &--person {
          font-size: 4.8vw;
          line-height: 5.867vw;
        }
        &--position {
          font-size: 3.733vw;
          line-height: 4.533vw;
        }
      }
      &-col2 {
        font-size: 3.2vw;
        line-height: 6.933vw;
        padding: 4.267vw 6.4vw 6.4vw 6.4vw;
        width: 71.2vw;
      }
      &-arrow {
        &--icon {
          width: 10.667vw;
          height: 10.667vw;
        }
      }
    }
  }
  .erp-header {
    padding: 6.4vw 8vw;
    &__logo {
      width: 28vw;
      height: 13.867vw;
    }
    &__tour {
      display: none;
      border-radius: 2.133vw;
      font-size: 4.267vw;
      line-height: 5.333vw;
      padding: 3.467vw 5.333vw 3.733vw 5.333vw;
      margin-top: 5.867vw;
    }
    &__burger {
      display: block;
      img {
        width: 6.667vw;
        height: 3.733vw;
      }
    }
    &__login {
      font-weight: 600;
      font-size: 4.267vw;
      line-height: 5.333vw;
      border-radius: 2.133vw;
      padding: 3.467vw 13.6vw;
      height: 12.533vw;
    }
  }
  .erp-help {
    height: 71.333vw;
    padding: 0 12vw;
    &__title {
      font-size: 6.4vw;
      line-height: 8vw;
    }
    &__text {
      font-size: 4.267vw;
      line-height: 6.933vw;
    }
    &__tour {
      &-btn {
        font-weight: 600;
        font-size: 4.267vw;
        line-height: 5.333vw;
        padding: 3.467vw 5.333vw 3.733vw 5.333vw;
        border-radius: 2.133vw;
      }
    }
  }
  .erp-nav {
    display: none;
    &__list {
      display: block;
    }
    &__item {
      font-weight: 500;
      font-size: 4.8vw;
      line-height: 5.867vw;
      margin-bottom: 5.867vw;
    }
    &__btns {
      padding: 8vw;
      display: flex;
      justify-content: center;
    }
  }
  .erp {
    &-footer {
      height: 136.267vw;
      width: auto;
      padding: 8vw;
      display: flex;
      flex-wrap: wrap;
      &__asa-pc {
        display: none
      }
      &__asa-mobile {
        display: block;
        padding-top: 5vw;
        width: 40vw;
        text-align: center;
      }
      &__logo {
        text-align: inherit;
        &-btn {
          width: 21.6vw;
          height: 10.667vw;
        }
      }
      &__col {
        width: 40vw;
        &:first-child {
          width:40vw;
        }
        &:nth-child(2) {
          width: 40vw;
        }
        &:nth-child(3) {
          width: 40vw;
        }
        &:nth-child(4) {
          width: 40vw;
        }
        &:last-child {
          width: 40vw;
        }
        &-title {
          font-weight: 600;
          font-size: 4.8vw;
          line-height: 5.867vw;
        }
        li {
          margin-bottom: 3.2vw;
        }
      }
      &__navigation {
        &--link {
          font-weight: 500;
          font-size: 3.733vw;
          line-height: 4.533vw;
        }
      }
      &__icon {
        height: 8.533vw;
        width: 8.533vw;
        margin-right: 4.8vw
      }
    }
  }
  .block-diptych {
    &__content {
      display: none;
      &--mobile {
        display: block;
      }
    }
    &__item {
      width: 100%;
      &:first-child {
        margin-right: 0;
      }
    }
  }
  .block-list {
    &__title {
      font-size: 6.4vw;
      line-height: 8vw;
    }
    &__list {
      padding: 0 8vw
    }
    &__item {
      display: none;
      &--mobile {
        display: flex;
        margin: 4.8vw 0;
      }
      &-number {
        font-size: 12.8vw;
        line-height: 15.733vw;
        margin-right: 13.333vw;
      }
      &-title {
        font-weight: 600;
        font-size: 4.8vw;
        line-height: 5.867vw;
        width: auto;
        p {
          line-height: 5.867vw;
        }
      }
      &-text {
        font-weight: 500;
        font-size: 3.733vw;
        line-height: 5.867vw;
        width: auto;
      }
    }
  }
  .timeline {
    padding: 0 8vw;
    &-item {
      display: none;
      &--mobile {
        display: flex;
        margin-bottom: 6.667vw;
      }
      &__line {
        align-items: normal;
        padding-top: 9.067vw;
        &:before {
          padding: 1.2vw;
          margin-left: -2.2vw;
        }
      }
      &__block {
        display: block;
        padding-top: 6.667vw;
      }
      &__date {
        font-size: 7.467vw;
        line-height: 9.067vw;
        margin-right: 9.067vw;
      }
      &__title {
        font-size: 4.8vw;
        line-height: 5.867vw;
        width: 100%;
        display: flex;
        align-items: center;
      }
      &__text {
        font-size: 3.733vw;
        line-height: 5.867vw;
        width: 100%;
      }
    }
  }
  .contact-us {
    padding: 0;
    &__title {
      font-size: 8.533vw;
      line-height: 8vw;
    }
    &__content {
      display: block;
    }
    &__form {
      width: auto;
      padding: 8vw;
      &--title {
        font-size: 5.333vw;
        line-height: 6.4vw;
      }
      &--row {
        display: block;
      }
      &--input {
        margin: 4.267vw 0;
        input {
          width: 84vw;
          font-size: 4.267vw;
          line-height: 5.333vw;
          padding: 3.2vw 0;
        }
      }
      &--textarea {
        font-size: 4.267vw;
        line-height: 5.333vw;
        width: 84vw;
      }
      &--btn {
        margin-top: 10.667vw
      }
      &--submit {
        padding: 3.2vw 12.8vw;
        font-size: 4.267vw;
        border-radius: 2.133vw;
        line-height: 5.333vw;
      }
    }
    &__info {
      width: auto;
      padding: 8vw;
      border-radius: 0;
      &--title {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 10.667vw;
      }
      &--phone {
        font-size: 4.267vw;
        line-height: 5.333vw;
        margin-bottom: 4.267vw;
      }
      &--email {
        font-size: 4.267vw;
        line-height: 5.333vw;
        margin-bottom: 4.267vw;
      }
      &--address {
        font-size: 4.267vw;
        line-height: 6.933vw;
        margin-bottom: 4.267vw;
      }
      &--social {
        margin-bottom: 6.4vw;
        &-icon {
          width: 8.533vw;
          height: 8.533vw;
          margin-right: 4.267vw
        }
      }
      &--map {
        margin-bottom: 5.867vw;
      }
      &--link-map {
        font-size: 4.267vw;
        line-height: 7.467vw;
        &-icon {
          width: 4.267vw;
          height: 4.267vw;
          margin-left: 2.667vw;
        }
      }
    }
  }
  .title-widget {
    &--margin-default {
      margin-bottom: 6.4vw;
    }
    &--margin-small {
      margin-bottom: 4.563vw;
    }
    &--margin-none {
      margin-bottom:0;
    }
    &__text {
      //font-size: 8.533vw;
      line-height: 9.067vw;
      width: 100%;
      padding: 0 8vw;
      &--48 {
        font-size: 8.533vw;
      }
      &--32 {
        font-size: 7.422vw;
      }
      &--28 {
        font-size: 7.144vw;
      }
      &--24 {
        font-size: 6.867vw;
      }
      &--18 {
        font-size: 5.450vw;
      }
      &--14 {
        font-size: 4.172vw;
      }
    }
  }
  .document {
    &-block {
      height: auto;
      display: flex;
      align-items: center;
      padding-left: 4.444vw;
    }
    &-file {
      padding-left: 1.694vw;
      padding-right: 3.694vw;
      &--icon {
        width: 8.14vw;
        height: 6.14vw;
      }
    }
    &-link, a:link, a:visited {
      font-size: 4.4vw;
      text-decoration: none;
      color: #455A7E;
      font-weight: 600;
      display: flex;
      align-content: center;
    }
    &-title {
      display: flex;
      align-items: center;
    }
  }
  .table {
    &-header {
      height: 11.125vw;
    }
  }
  .erp-global-search--btn {
    display: none !important;
  }
}

